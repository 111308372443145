import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TiktokEventsService } from './tiktok-events.service';

const httpOption = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
const headers = new Headers();

@Injectable({
  providedIn: 'root'
})




export class ServiciowishlistService {

  constructor(public http: HttpClient, private tikttokEvents: TiktokEventsService) { }


  sendWishlist(datosWishlist: any) {
    console.log({ datosWishlist })
    this.tikttokEvents.sendEvent({ event: 'AddToWishlist', value: datosWishlist.precio })
    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/registraWishlist.php', datosWishlist);
  }

  recuperaWishlist(datosCorreo: any) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/getWishlist.php?datosCorreo=' + datosCorreo);
  }

  recuperaWishlist2(datosCorreo: any) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/getWishlist2.php?datosCorreo=' + datosCorreo);
  }

  eliminarWishlist(datosWishlist: any) {
    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/eliminaWishlist.php', datosWishlist);
  }

  eliminaRegalo(datosWishlist: any) {
    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/eliminaRegalo.php', datosWishlist);
  }



  recuperaBlog() {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/blog.php');
  }

  recuperaArticulo(id_post: any) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/articulo-blog.php?id_post=' + id_post);
  }
}
