import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as Sentry from "@sentry/angular";
import { BehaviorSubject, from, Observable, of } from 'rxjs';

import { map, switchMap } from 'rxjs/operators';
import { Login } from '../Models/login.interface';
import { Usuario } from '../Models/usuario.interface';
import { IUserData } from '../types/userData';


const httpOption = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  url: string = "https://new.lideart.com.mx/Automatizacion/apis/login.php";

  private usuarioSubject: BehaviorSubject<Usuario | null>;
  public usuario: Observable<Usuario | null>;

  userData?: IUserData

  public get usuarioData(): Usuario | null {
    return this.usuarioSubject.value;
  }

  constructor(public http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {
    this.usuarioSubject = new BehaviorSubject<Usuario | null>(null);
    this.usuario = this.usuarioSubject.asObservable();
    console.log('Se ejecuenta en', platformId)
    if (isPlatformBrowser(this.platformId)) {
      const usInfo = localStorage.getItem('usuario');
      if (usInfo) {
        const usParse = JSON.parse(usInfo) as Usuario;
        this.usuarioSubject.next(usParse);
        console.log("info de usuario", { usParse })
      }
      this.comprobateUserInfo();
    }
  }


  // * Esta funcion sirve para que cada que entre un usuario al sitio, verificar si esta esta logeado y en dado caso que si, recuperar la informacion de usuario;
  // * Tambien verifica que dicha informacion este "actualizada" (por si hay multicuentas.)
  private async comprobateUserInfo(): Promise<void> {

    if (this.usuarioSubject.value === null) {
      localStorage.removeItem('userData');
      return
    }
    const rawUserInfo = localStorage.getItem('userData');
    if (rawUserInfo === null) {
      await this.fetchUserData();
      return
    }
    const tempUserInfo = JSON.parse(rawUserInfo) as IUserData
    if (tempUserInfo.correo !== this.usuarioSubject.value?.usuario) {
      await this.fetchUserData();
      return
    }
    Sentry.setUser({ email: tempUserInfo.correo, id: tempUserInfo.idCustomer, username: tempUserInfo.nombre });
  }

  private async fetchUserData() {
    try {
      const resp = await this.http.get(`https://new.lideart.com.mx/Automatizacion/apis/getDatosCliente.php?email=${this.usuarioSubject.value?.usuario}`).toPromise() as { nombre_cliente: string, email: string, customerID: string, id_customer: string, phone: string }[]

      const nombrePartido = resp[0].nombre_cliente.split(' ')
      let nombre = ''
      let apellido = ''
      if (nombrePartido.length > 2) {
        apellido = `${nombrePartido[nombrePartido.length - 2]} ${nombrePartido[nombrePartido.length - 1]}`;
        nombre = nombrePartido.slice(0, nombrePartido.length - 2).join(' ');
      } else if (nombrePartido.length === 2) {
        nombre = nombrePartido[0];
        apellido = nombrePartido[1];
      } else {
        nombre = nombrePartido[0];
      }


      //Transformat a tipo:
      const userd: IUserData = {
        correo: resp[0].email,
        apellido: resp[0].nombre_cliente,
        idCustomer: resp[0].id_customer,
        idDynamics: resp[0].customerID,
        nombre: resp[0].nombre_cliente,
        telefono: resp[0].phone
      }
      localStorage.setItem('userData', JSON.stringify(userd));
      Sentry.setUser({ email: userd.correo, id: userd.idCustomer, username: userd.nombre });
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  }

  getUserData() {

    //! Hotfix: esto se hace para prevenir crasheo al agregar al carrito.
    if (typeof localStorage === 'undefined') {
      return undefined
    }


    const rawData = localStorage.getItem('userData');
    if (rawData == null) return undefined
    return JSON.parse(rawData) as IUserData
  }

  login(Login: Login): Observable<any> {
    return this.http.post<any>(this.url, Login, httpOption).pipe(
      switchMap(res => {
        if (res.exito === 1) {
          const usuario: Usuario = { usuario: res.usuario, token: res.token };
          localStorage.setItem('usuario', JSON.stringify(usuario));
          this.usuarioSubject.next(usuario);
          return from(this.fetchUserData()).pipe(
            map(() => res)
          );
        }
        return of(res);
      })
    );
  }

  logout() {
    localStorage.removeItem('usuario');
    localStorage.removeItem('userData');
    Sentry.setUser(null);
    this.usuarioSubject.next(null!);
  }

  resetMe(key: string, token: string, password: string, password2: string) {
    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/updatePassword.php', { key, token, password, password2 });
  }


  requestReset(email: string) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/passwordReset.php?email=' + email);
  }

  checaMesa(email: string) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/checaMesa.php?email=' + email);
  }

  checaMesa2(email: string) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/checaMesa2.php?email=' + email);
  }

  checaRegalos(email: string) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/checaRegalos.php?email=' + email);
  }

  actualizaDirColonia(datosCorreo: any) {
    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/actualizaDirColonia.php', datosCorreo);
  }

  checaUsuario(correo: string | null) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/checaUsuario.php?email=' + correo);
  }

  checaUsuarioMesa(correo: string) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/checaUsuario2.php?email=' + correo);
  }

  EnviaCorreoRecuperaPassword(datosCorreo: any) {
    return this.http.post('https://lideart.aytcloud.com/inaxLideartExpoConsAPI/api/email/enviarRecuperacionLideart', datosCorreo);
  }

  VerificaDatosSAT(datosCorreo: any) {
    return this.http.post('https://inax2.aytcloud.com/inaxapilideart/api/cliente/verifarDatosSAT', datosCorreo);
  }

  RefacturarSat(datosCorreo: any) {
    return this.http.post('https://inax2.aytcloud.com/inaxapilideart/api/venta/generearRefacturacionMasivaCambioCliente', datosCorreo);
  }




  recuperaDatosCliente(datosCorreo: any) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/recuperaDatosCliente.php?datosCorreo=' + datosCorreo);
  }

  traePuntos(datosCorreo: any) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/traePuntos.php?datosCorreo=' + datosCorreo);
  }

  actualizaDatosCliente(datosCorreo: any) {
    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/actualizaDatosCliente.php', datosCorreo);
  }
  recuperaDatosDireccion(datosCorreo: any) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/recuperaDireccion.php?datosCorreo=' + datosCorreo);
  }

  recuperaOrdenescliente(cliente: any) {
    return this.http.get<Response>('https://lideart.aytcloud.com/inaxLideartExpoConsAPI/api/venta/getEspecificClientOrdenesVenta/' + cliente);
  }

  DetalledeVenta(OV: any) {
    return this.http.get<Response>('https://lideart.aytcloud.com/inaxLideartExpoConsAPI/api/ProductosVenta/getLineasOrdenVenta/' + OV);
  }

  DetalledeVenta2(OV: any) {
    return this.http.get<Response>('https://lideart.aytcloud.com/inaxLideartExpoConsAPI/api/venta/getSpecificOrdenVenta/' + OV);
  }

  EstadoPedido(OV: any) {
    return this.http.get<Response>("https://new.lideart.com.mx/Automatizacion/apis/consultaEstadoPedido.php?ov=" + OV);
  }

  BorrarDatosDir(datosCorreo: any) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/BorrarDatosDir.php?datosCorreo=' + datosCorreo);
  }

  selectDireccionZipCode(accion: string, postCode: string) {
    return this.http.post<Response>('https://new.avanceytec.com.mx/Automatizacion/direcciones/address.php', { accion, postCode });
  }

  selectDireccionState(accion: string, state: string) {
    return this.http.post<Response>('https://new.avanceytec.com.mx/Automatizacion/direcciones/address.php', { accion, state });
  }

  selectDireccionCity(accion: string, city: string) {
    return this.http.post<Response>('https://new.avanceytec.com.mx/Automatizacion/direcciones/address.php', { accion, city });
  }
  enviaDireccion(direccion: any) {

    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/registraDireccion.php', direccion);

  }

  enviaDireccionMesa(direccion: any) {

    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/registraDireccionMesa.php', direccion);

  }

  actualizaDatosDireccion(datosCorreo: any) {
    // return this.http.post('https://new.avanceytec.com.mx/Automatizacion/apis/actualizaDatosDireccion.php',datosCorreo);
    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/actualizaDatosDireccion.php', datosCorreo);
  }

  actualizaDatosDireccionMesa(datosCorreo: any) {
    // return this.http.post('https://new.avanceytec.com.mx/Automatizacion/apis/actualizaDatosDireccion.php',datosCorreo);
    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/actualizaDatosDireccionMesa.php', datosCorreo);
  }

  guardaDatosFactura(direccion: any) {

    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/guardarDatosFactura.php', direccion);

  }

  borrarCliente(datosCorreo: any) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/borrarCliente.php?datosCorreo=' + datosCorreo);
  }

  consultaDatosFactura(datosCorreo: any) {

    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/consultaDatosFactura.php?email=' + datosCorreo);

  }

  checaCountyID(datosCorreo: any) {
    return this.http.post('https://new.avanceytec.com.mx/Automatizacion/apis/checaCountyID.php', datosCorreo);
  }

  revisaDescuento(datosCorreo: any) {
    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/checaCarrito.php', datosCorreo);
  }


  checasihayDyn(cutomerID: any) {
    return this.http.get<Response>('https://inax2.aytcloud.com/inaxapilideart/api/cliente/getClientAddresses/' + cutomerID);

  }


  getReviews() {

    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/reviews.php');

  }

  registraReviews(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/registraReviews.php', datos);
  }

  checaVersion() {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/checaVersion.php');
  }

  registraBuscador(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/buscador.php', datos);
  }

  mandaEscuestaSub(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/registraSubdistribuidor.php', datos);
  }

  mandaEncuestaExpo(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/registraEncuesta.php', datos);
  }

  mandaPerfilCliente(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/registroPerfil.php', datos);
  }

  actualizaPerfil(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/actualizaPerfil.php', datos);
  }

  checaPerfil(datosCorreo: any) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/checaPerfil.php?correo=' + datosCorreo);
  }

  registraMesa(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/registraMesa.php', datos);
  }

  registraRegaloMesa(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/registraRegalo.php', datos);
  }


  registraJsonMesa(datos: any = []) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/registraJsonMesa.php', datos);
  }

  encuestaSatisfaccion(datosEncuesta: any) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/encuestaSatisfaccion.php', datosEncuesta);
  }
}
